.Header-header {
  position: fixed;
  width: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  background-color: #282c34;
  min-height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: white;
}

.Header-title,
.Header-navigator {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  top: 0;
  left: 0;
}

.Header-title {
  position: relative;
  top: -1px;
  margin-left: 20px;
  font-size: 22px;
}

.Header-navigator {
  margin-left: auto;
  margin-right: 15px;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.Header-navigator span {
  display: flex;
  line-height: 30px;
}

.Header-account_san {
  margin-left: 7px;
}

.Header-account_separator {
  margin: 0 7px;
}
