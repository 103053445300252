.PlaceCreatePage-form {
  margin: 0 18px;
}

.PlaceCreatePage-create_button {
  margin-top: 18px;
  width: 120px;
}

.PlaceCreatePage-client_select {
  width: 300px;
}

.PlaceCreatePage-place_name_input {
  width: 380px;
}

.PlaceCreatePage-place_chief_name_input {
  width: 240px;
}

.PlaceCreatePage-input_container {
  margin-top: 20px;
}

.PlaceCreatePage-button_container {
  margin-top: 30px;
}
