.PlacePage-container {
  width: 100%;
}

.PlacePage-container h1 {
  margin-left: 8px;
  color: rgb(41, 41, 41);
  font-size: 25px;
  font-weight: bold;
}

.PlacePage-navigator {
  border-bottom: 5px solid rgb(231, 231, 231);
}

.PlacePage-navigator ul {
  position: relative;
  top: 5px;
  display: flex;
  padding: 0;
  margin: 0;
}

.PlacePage-navigator li {
  display: flex;
  padding: 8px 24px;
}

.PlacePage-navigator a {
  text-decoration: none;
  color: rgb(165, 100, 26);
}

.PlacePage-navigator_selected {
  border-bottom: 5px solid rgb(165, 100, 26);
}

.PlacePage-navigator_items_count {
  background-color: rgb(55, 161, 211);
  color: #fff;
  border-radius: 100%;
  padding: 0px 10px;
  margin-left: 10px;
  font-size: 12px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center; /* 縦方向中央揃え（Safari用） */
  align-items: center; /* 縦方向中央揃え */
  -webkit-justify-content: center; /* 横方向中央揃え（Safari用） */
  justify-content: center; /* 横方向中央揃え */
}

.PlacePage-navigator_items_count.red {
  background-color: rgb(230, 74, 74);
}
