.PlaceIndexPage-container {
  margin-top: 24px;
  padding: 12px 24px;
  width: max-content;
  min-width: 480px;
}

.PlaceIndexPage-button_container {
  margin-top: 10px;
}

.PlaceIndexPage-button_container:nth-child(1) {
  margin-top: 40px;
}
